// @import "./antd.less"; // Import Ant Design styles by less entry
// @import "../node_modules/antd/dist/antd.less";
@import "~antd/dist/antd.less";
// @import "./antd.less";

@lightGreen: #fbfff0;
@green: #78be20;
@white: #fff;
@black: #000;
@grey: #ddd;

@primary-color: @green;

.ant-layout-sider-children,
.ant-menu {
  background-color: @green;
  color: @black;
}
.ant-menu-item a:hover {
  color: @black;
}
.ant-menu-item:hover {
  color: @black !important;
}
.ant-layout-header {
  background: @white;
  border-bottom: 1px solid @grey;
  box-shadow: 3px 3px 3px @grey;
  height: 70px;
}
.ant-carousel .slick-dots-bottom {
  bottom: -20px;
}
.ant-carousel .slick-dots {
  background-color: @black;
}
.ant-statistic-title {
  font-weight: bold;
  color: @black;
}

@primary-color: #78BE20;