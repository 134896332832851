* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background-color: #f0f2f5;
}
a {
  text-decoration: none !important;
}
.ant-btn,
.ant-btn.btn-shadow {
  box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgb(0 0 0 / 25%);
  -webkit-box-shadow: 0 0 transparent, 0 0 transparent,
    0 1px 2px 0 rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 0 transparent, 0 0 transparent,
    0 1px 2px 0 rgb(0 0 0 / 25%);
  -ms-box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgb(0 0 0 / 25%);
}
#root {
  height: 100%;
}
.anticon svg {
  vertical-align: baseline;
}

.inline-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(142, 142, 142, 0.4);
}

.login-input .ant-input {
  height: 50px !important;
  border-radius: 8px;
}
.login-input .ant-input-affix-wrapper > input.ant-input {
  height: 40px !important;
}
.login-input .ant-input-affix-wrapper {
  border-radius: 8px;
}
.content-wrapper {
  padding-bottom: 50px;
}
.ant-form-item-with-help {
  margin-bottom: 5px;
}

.ant-table-thead.customize-header th {
  background-color: #333;
  color: #fff;
}

.receipt-wrapper {
  min-height: 480px;
}
.tab-name {
  cursor: pointer;
}
.text-login {
  color: #78be20;
  font-size: 16px;
}
.hr-login {
  background-color: #78be20;
  width: 130px;
}
.header-title {
  color: #000 !important;
  font-weight: bold;
  font-size: 18px;
}
.ant-layout-sider {
  background-color: #fff !important;
  box-shadow: 2px 2px 2px #ddd;
}
.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: #333 !important;
  border-top: 1px solid #ddd;
}
.div-breadcrumb {
  background-color: #fff;
  /* height: 120px; */
  padding: 12px 24px;
  margin: -24px -24px 24px -24px;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 999;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
.p-breadcrumb {
  color: #888;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
}
.p-breadcrumb-title {
  color: #333;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
}
.span-breadcrumb-title {
  color: #888;
  font-size: 12px;
}
.selectedClub {
  background-color: #e1f2ff;
}
.ant-table-tbody > tr.selectedClub.ant-table-row:hover > td {
  background-color: #e1f2ff !important;
}
.leaflet-container {
  width: 100%;
  min-height: calc(100vh - 250px);
  height: 100%;
}
.leaflet-popup-content p {
  margin: 5px 0;
}
.icon-map-location {
  border-radius: 100%;
  border: 3px solid#315dee;
}
.icon-map-location.icon-map-selected {
  border: 3px solid #36f11e;
}
.text-forget {
  color: #78be20;
  cursor: pointer;
}
.map-container {
  top: 200px;
  width: 100%;
  height: 100%;
  left: 105px;
}
.text-red {
  color: red !important;
}
.pointer {
  cursor: pointer;
}
.inputnumber-onlyread .ant-input-number-input {
  font-weight: bold !important;
  text-align: right;
  padding: 0;
}
.div-item-comment {
  border-bottom: 1px solid #ddd;
  margin: 10px 0 0 0;
  padding-bottom: 10px;
  word-break: break-all;
}
.div-item-comment:last-child {
  border-bottom: none;
  margin: 10px 0 0 0;
  padding-bottom: 10px;
}
.comment-time {
  font-size: 12px;
}
.text-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer {
  width: calc(100% + 48px);
  background-color: #fff;
  margin: 20px -24px 0 -24px !important;
  padding: 10px 24px;
  font-size: 12px;
}
.login-footer {
  width: 100%;
  background-color: #fff;
  margin: 0px !important;
  padding: 10px 24px;
  font-size: 12px;
  z-index: 99;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.dashboard-title {
  background-color: #fff;
  padding: 10px 24px;
  margin-bottom: 20px;
  font-size: 14px;
  width: max-content;
}
.tabs-card {
  background-color: #fff;
  border: 1px solid #ddd;
}
.tabs-card .ant-tabs-tab {
  width: 100px;
}
.splide__arrow--next {
  right: -30px !important;
  border: 2px solid #333 !important;
  background: transparent !important;
}
.splide__arrow--prev {
  left: -30px !important;
  border: 2px solid #333 !important;
  background: transparent !important;
}
.splide__arrow svg {
  fill: #333 !important;
}

.text-video {
  position: absolute;
  bottom: 20px;
  left: 60px;
  color: red;
  background-color: #315dee;
}
.green-text-login {
  color: #78be20;
  font-weight: 700;
  font-size: 33px;
}
.login-input .ant-input-group-addon {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #78be20;
  width: 50px;
  box-shadow: 0px 4px 4px 0px #ddd;
}
.login-btn {
  height: 40px !important;
  border-radius: 8px !important;
}
.div-form-login {
  border: 1px solid #78be20;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 30px;
}
.form-login {
  width: 100%;
  text-align: center;
  padding-top: 10% !important;
  padding-bottom: 30px !important;
}
.span-return {
  text-align: left;
  position: absolute;
  bottom: 10px;
  left: 30px;
  font-size: 12px;
  cursor: pointer;
}
.course-upload .ant-upload-list-picture-card-container,
.course-upload .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 30vh;
}
.div-item-lesson {
  width: 100%;
  border: 2px dashed #78be20;
  border-radius: 16px;
  padding: 50px 20px;
  margin-bottom: 50px;
  overflow: hidden;
}
.div-title-item-lesson {
  width: calc(100% - 40px);
  height: 40px;
  text-align: center;
  border: 1px solid #78be20;
  border-radius: 4px;
  z-index: 10;
  padding: 8px 20px;
  background-color: #fff;
  font-weight: bold;
  color: #78be20;
  margin-bottom: -20px;
  margin-left: 20px;
}
.div-save-update {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
  background-color: #fff;
  padding: 10px 32px;
  border-top: 1px solid #78be20;
  width: 100%;
  text-align: right;
}

.card-p0 > .ant-card-body {
  padding: 0;
}

.exam-statistical {
  text-align: center;
}

.exam-statistical h3,
.exam-statistical span {
  font-size: 20px;
  line-height: 1.2;
}

.exam-statistical h3 {
  margin-bottom: 5px;
}

.exam-statistical span {
  font-weight: 700;
}

@media screen and (min-width: 1200px) {
  /* .map-container {
    position: fixed;
    width: 50%;
    left: 220px;
  } */
}
